"use client";

import { faArrowRightFromBracket } from "@fortawesome/pro-solid-svg-icons/faArrowRightFromBracket";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons/faChartLine";
import { faFileArrowUp } from "@fortawesome/pro-solid-svg-icons/faFileArrowUp";
import { faFilter } from "@fortawesome/pro-solid-svg-icons/faFilter";
import { faLocationDot } from "@fortawesome/pro-solid-svg-icons/faLocationDot";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button, Flex, Image, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { twMerge } from "tailwind-merge";

import { AccountModal } from "@/components/account-modal";
import CsvModal from "@/components/csvModal/csvModal";
import { useUserContext } from "@/context/user.context";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { closeExtension } from "@/utils/extension";
import { LAYOUT_DIMENSIONS, MANTINE_COLORS } from "@/utils/ui";

const getSharedButtonProps = (isCurrentLink = false) => ({
  className: twMerge("uppercase"),
  fw: 600,
  pl: 6,
  size: "xs",
  styles: {
    inner: {
      // Prevent border from offsetting content relative to other links.
      marginTop: isCurrentLink ? 1.5 : 0,
    },
    root: {
      borderBottom: isCurrentLink
        ? `3px solid ${MANTINE_COLORS.primary}`
        : "none",
      borderRadius: 0,
      height: 48,
    },
  },
  variant: "transparent",
});

interface HeaderProps {
  hideAccountButtons?: boolean;
}

export const Header = ({ hideAccountButtons = false }: HeaderProps) => {
  const track = useTrackAnalytics();
  const pathname = usePathname();
  const { logOut, user } = useUserContext();
  const [
    accountModalOpened,
    { close: closeAccountModal, open: openAccountModal },
  ] = useDisclosure(false);
  const [csvModalOpened, { open: openCsvModal, close: closeCsvModal }] =
    useDisclosure(false);

  return (
    <header
      className={twMerge([
        "bg-white",
        "border-b-2",
        "border-gray-2000",
        "flex",
        "items-center",
        "justify-between",
        "px-[18px]",
        "w-full",
        "z-40",
      ])}
      style={{ height: LAYOUT_DIMENSIONS.HEADER_HEIGHT_PX }}
    >
      <div className="flex gap-6 items-center">
        <Link href="/">
          <Image src="/assets/logo.svg" alt="Logo" h={20} />
        </Link>
        <Text size="sm" fw={600}>
          {user?.company.name || ""}
        </Text>
      </div>
      <Flex align="center">
        {user && (
          <>
            {user.internal && (
              <Button
                {...getSharedButtonProps()}
                onClick={() => openCsvModal()}
                rightSection={<FontAwesomeIcon icon={faFileArrowUp} />}
              >
                Upload CSV
              </Button>
            )}
            {!hideAccountButtons && (
              <>
                <Button
                  {...getSharedButtonProps(
                    pathname === "/" ||
                      pathname.startsWith("/screener") ||
                      pathname.startsWith("/screener-location"),
                  )}
                  component={Link}
                  href="/"
                  rightSection={<FontAwesomeIcon icon={faFilter} />}
                >
                  Screeners
                </Button>
                <Button
                  {...getSharedButtonProps(pathname === "/assigned-to/me")}
                  component={Link}
                  href="/assigned-to/me"
                  rightSection={<FontAwesomeIcon icon={faLocationDot} />}
                >
                  My Locations
                </Button>
                <Button
                  {...getSharedButtonProps(pathname === "/analytics")}
                  component={Link}
                  href="/analytics"
                  onClick={() => track("Analytics Link Clicked")}
                  rightSection={<FontAwesomeIcon icon={faChartLine} />}
                >
                  Analytics
                </Button>
                <Button
                  {...getSharedButtonProps()}
                  onClick={() => {
                    track("Account Button Clicked");
                    openAccountModal();
                  }}
                  rightSection={<FontAwesomeIcon icon={faUser} />}
                >
                  Account
                </Button>
                <Button
                  {...getSharedButtonProps()}
                  component={Link}
                  href="/auth/login?logout=true"
                  onClick={() => logOut()}
                  rightSection={
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  }
                >
                  Log out
                </Button>
              </>
            )}
          </>
        )}
        <ActionIcon
          variant="transparent"
          onClick={() => closeExtension()}
          aria-label="close"
        >
          <Image src="/assets/circle-x.svg" alt="Logo" h={20} />
        </ActionIcon>
      </Flex>
      <AccountModal onClose={closeAccountModal} opened={accountModalOpened} />
      <CsvModal opened={csvModalOpened} onClose={closeCsvModal} />
    </header>
  );
};
