import type { ApiColumn } from "@/types/api";
import type { AllLocationsResponseScreener } from "@/types/screeners";
import type { UserListUser } from "@/types/user";
import type { Nullable } from "@/types/utils";

// Life would be so much better if TS just let me extend enums.
// This is broken into two enums - Status and MetaStatus. This is done to ease
// typing, because an individual location cannot have a "Mixed" status. "Mixed"
// only emerges when looking at an aggregate of screener locations
export const IndividualScreenerLocationStatus = {
  Live: "Live",
  Paused: "Paused",
} as const;

const ScreenerLocationMetaStatus = {
  Mixed: "Mixed",
} as const;

export const ScreenerLocationStatus = {
  ...IndividualScreenerLocationStatus,
  ...ScreenerLocationMetaStatus,
} as const;

export type ScreenerLocationStatusType =
  (typeof ScreenerLocationStatus)[keyof typeof ScreenerLocationStatus];

export type IndividualScreenerLocationStatusType =
  (typeof IndividualScreenerLocationStatus)[keyof typeof IndividualScreenerLocationStatus];

export interface ScreenerLocation {
  assignedAt: Nullable<string>;
  assignedTo: Nullable<UserListUser>;
  atsJobId: Nullable<string>;
  createdAt: string;
  id: string;
  internalName?: string;
  jobName: string;
  lastSubmissionDate: Nullable<string>;
  locationName: string;
  // These will only appear on the manage locations response. Locations table
  // data makes use of the pre-formatted `wage` property
  maxPayHourly?: number;
  minPayHourly?: number;
  positionLink: string;
  sessionCount?: number;
  shareLink: Nullable<string>;
  shareLinkSlug: Nullable<string>;
  status: IndividualScreenerLocationStatusType;
  unviewedSessionCount: number;
  wage?: string;
}

export interface ScreenerLocationDraft {
  atsJobId: Nullable<string>;
  id: string;
  // This property only exists on the draft, it is initially inferred from the
  // existence of min/max pay
  includeWages: boolean;
  // Another property that only exists on the draft. Used to indicate whether
  // this is a brand new location or a location being edited.
  isNew: boolean;
  locationName: string;
  maxPayHourly?: number;
  minPayHourly?: number;
  sessionCount?: number;
  shareLinkSlug: Nullable<string>;
  // This will always be "Live" for new screeners
  status: IndividualScreenerLocationStatusType;
}

export const propertiesToCleanFromLocationDraft = [
  "includeWages",
  "id",
  "isNew",
  "sessionCount",
] as const;
export interface CleanLocationDraftForServer
  extends Omit<
    ScreenerLocationDraft,
    | (typeof propertiesToCleanFromLocationDraft)[number]
    // We exclude and then re-add min/max pay here to transform their type from
    // Maybe<number> to Nullable<number> as JSON does not support undefined
    | "maxPayHourly"
    | "minPayHourly"
  > {
  maxPayHourly: Nullable<number>;
  minPayHourly: Nullable<number>;
}

export interface ScreenerLocationsTableResponse {
  columns: ApiColumn<ScreenerLocation>[];
  screenerLocations: ScreenerLocation[];
}

export interface AllLocationsResponse extends ScreenerLocationsTableResponse {
  screener: AllLocationsResponseScreener;
}
